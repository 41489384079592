<script>
	import { onMount, createEventDispatcher } from 'svelte';
	import { slide } from 'svelte/transition';
	import { push, replace as replaceRoute } from 'svelte-spa-router';
	import LoginProvider from './LoginProvider.svelte';
	import LoginProviderGroup from './LoginProviderGroup.svelte';
	import HelpButton from './button/HelpButton.svelte';
	import MailIcon from './icon/MailIcon.svelte';
	import PhoneIcon from './icon/PhoneIcon.svelte';
	import VerifyEmail from './VerifyEmail.svelte';
	import VerifyPhone from './VerifyPhone.svelte';
	import EthereumProgressModal from './modal/EthereumProgressModal.svelte';
	import Dropdown from './Dropdown.svelte';
	import {
		data,
		notification,
		showSpinner,
		mergeAccounts,
		showUpgradePreferredModal,
		isRemoteAuthClient
	} from '../stores.js';
	import { _, locale } from 'svelte-i18n';
	import {
		postLoginProvider,
		postLoginEth,
		postLoginEthChallenge,
		getConsent,
		getProfile
	} from '../utils/api-calls.js';
	import {
		trimEthAddress,
		logPlausibleEvent,
		getWallet,
		getDisplay,
		handleConsentResponse,
		getRecommendedProviders
	} from '../utils/helper.js';
	import tippy from 'sveltejs-tippy';
	import { getLoginChallenge, postLoginChallenge } from '../passkey.js';
	import { startAuthentication } from '@simplewebauthn/browser';
	import { WALLETCONNECT_CONFIG } from '../constants';

	import { WalletConnectModalSign } from '@walletconnect/modal-sign-html';
	import { getAddressFromAccount } from '@walletconnect/utils';
	import SpinnerIcon from './icon/SpinnerIcon.svelte';

	let web3ModalSign;

	const dispatch = createEventDispatcher();

	export let rememberPrompt = false;
	export let contactLogin;
	export let provider_hints = {
		shown: [],
		hidden: []
	};
	export let continueWithQRCodeAjax = false;
	export let showPasskeyLogin = false;
	export let login_hint = {};

	const state = Object.preventExtensions({
		button: {
			show_recoveries: false,
			show_all: false,
			email: {
				default: false
			},
			phone: {
				default: false
			},
			provider: null
		}
	});
	let ethereumProgressModal;
	let ethereumProgressNotifs = [];
	$: loggedInSubject = $data?.recovery?.find((i) => i.id === $data?.subjects[0]);

	export let getData = () => {};

	onMount(() => {
		//remove all outstanding modals
		$showUpgradePreferredModal = null;
		$mergeAccounts = null;
	});

	let recommendedProviders = ['google', 'email'];
	//this runs everytime provider_hints are changed
	$: {
		recommendedProviders = getRecommendedProviders(provider_hints.shown, provider_hints.hidden);
	}

	async function contactLoginSuccess(e) {
		const res = await contactLogin(e.detail);
		// $notification = {
		// 	text: '',
		// 	type: ''
		// }
		if (!res?.uri && !res?.response_mode) {
			push('/');
		}
	}

	function handleContactError(err) {
		if (err.detail.status !== 404) {
			state.button.show_all = state.button.phone.default = state.button.email.default = false;
		}
	}

	async function continueWithEthereumExtension(info) {
		const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
		ethereumProgressModal = 'extension';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign...')
			}
		];
		if (info?.login_hint && info?.login_hint.toLowerCase() !== address.toLowerCase()) {
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Expected ({address})', {
						values: {
							address: trimEthAddress(info.login_hint)
						}
					}),
					type: 'error',
					status: address
				}
			];
			return;
		}
		continueEthExtensionSigning(address);
	}

	async function continueEthExtensionSigning(address) {
		let challenge, signature;
		const slug = getWallet().slug;
		try {
			const res = await postLoginEth(address);
			logPlausibleEvent({ u: `/start/login/ethereum/extension/${slug}`, n: 'action' });
			challenge = res.challenge;
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					status: $_('Waiting to sign...')
				}
			];
		} catch (err) {
			console.error(err);
		}

		//New User Release Funnel
		if (window.isWalletAuthorizeApp) {
			const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
				sessionStorage.az_release_funnel
			);
			const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_start');
			//session funnel state is valid and not already sent + is authorize app
			if (
				indexOfCurrentFunnelStep !== -1 &&
				indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
				window.isWalletAuthorizeApp
			) {
				const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
					'client_id'
				);
				const isRecommendedProvider = recommendedProviders.includes(slug);
				const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
					'redirect_uri'
				);
				let redirect;
				try {
					redirect = new URL(redirect_uri)?.hostname;
				} catch (err) {
					console.error(err);
				}
				logPlausibleEvent({
					n: 'AZ Login Start',
					p: { client_id, provider: slug, recommended_provider: isRecommendedProvider, redirect },
					u: '/login'
				});
				sessionStorage.setItem('az_release_funnel', 'az_login_start');
			}
		}

		try {
			signature = await window.ethereum.request({
				method: 'personal_sign',
				params: [address, challenge]
			});
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Message signed'),
					type: 'success',
					status: $_('Logging you in...')
				}
			];
		} catch (err) {
			console.info(err);
			if (err.code === 4001) {
				$notification = {
					text: $_("You've rejected the sign request"),
					type: 'error'
				};
			} else {
				$notification = {
					text: $_('Something went wrong. Please try again later.'),
					type: 'error'
				};
			}
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const body = {
			signature,
			address,
			icon: getWallet().icon,
			name: getWallet().name
		};

		try {
			const res = await postLoginEthChallenge(body);
			await logPlausibleEvent({ u: `/login/ethereum/extension/${getWallet().slug}`, n: 'action' });
			$data = await getData();

			//New User Release Funnel
			if (window.isWalletAuthorizeApp) {
				const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
					sessionStorage.az_release_funnel
				);
				const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_success');
				//session funnel state is valid and not already sent + is authorize app
				if (
					indexOfCurrentFunnelStep !== -1 &&
					indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
					window.isWalletAuthorizeApp
				) {
					const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
						'client_id'
					);
					const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
						'redirect_uri'
					);
					let redirect;
					try {
						redirect = new URL(redirect_uri)?.hostname;
					} catch (err) {
						console.error(err);
					}
					if (
						!$data?.uri &&
						!$data?.response_mode &&
						$data?.isNewUser &&
						!$data?.release?.previous
					) {
						//existing user: not auto-flow + isNewUser + not rerelease
						await logPlausibleEvent({
							n: 'AZ Login Success',
							p: { client_id, provider: slug, redirect },
							u: '/login'
						});
						sessionStorage.setItem('az_release_funnel', 'az_login_success');
					} else {
						await logPlausibleEvent({
							n: 'AZ Existing User',
							p: { client_id, provider: slug, redirect },
							u: '/login'
						});
						sessionStorage.removeItem('az_release_funnel');
					}
				}
			}

			if (!res?.uri && !res?.response_mode) {
				push('/');
			} else {
				$showSpinner = true;
				handleConsentResponse(res);
			}
		} catch (err) {
			console.error(err);
			const json = await err.json();
			if (err.status === 403) {
				if (json?.error?.message === 'ACCESS_DENIED') {
					$notification = {
						text: 'User does not have access to Dev Redirect URIs',
						type: 'error'
					};
				}
			}
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
		}
	}

	async function toggleNoPrompt(e) {
		e.preventDefault();
		try {
			const res = await fetch('/api/v1/device/no_prompt', {
				method: e.target.checked ? 'POST' : 'DELETE'
			});
			if (!res.ok) {
				throw res;
			} else {
				e.target.checked = !e.target.checked;
			}
		} catch (err) {
			console.error(err);
			$notification = {
				text: $_('Something went wrong. Please try again later.'),
				type: 'error'
			};
		}
	}

	async function continueWithProvider(slug, body, server) {
		try {
			$showSpinner = true;
			const { redirect } = await postLoginProvider({
				slug,
				body,
				server,
				access: window.isWalletMastodonApp && ['mastodon', 'twitter'].includes(slug)
			});

			//New User Release Funnel
			if (window.isWalletAuthorizeApp) {
				const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
					sessionStorage.az_release_funnel
				);
				const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_start');
				//session funnel state is valid and not already sent + is authorize app
				if (
					indexOfCurrentFunnelStep !== -1 &&
					indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
					window.isWalletAuthorizeApp
				) {
					const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
						'client_id'
					);
					const isRecommendedProvider = recommendedProviders.includes(slug);
					const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
						'redirect_uri'
					);
					let redirect;
					try {
						redirect = new URL(redirect_uri)?.hostname;
					} catch (err) {
						console.error(err);
					}
					await logPlausibleEvent({
						n: 'AZ Login Start',
						p: { client_id, provider: slug, recommended_provider: isRecommendedProvider, redirect },
						u: '/login'
					});
					sessionStorage.setItem('az_release_funnel', 'az_login_start');
				}
			}

			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	let session;
	async function continueWithWalletConnect(info) {
		try {
			web3ModalSign = new WalletConnectModalSign(WALLETCONNECT_CONFIG);
		} catch (err) {
			console.error(err);
			setTimeout(() => {
				//tbd : remove timeout - something is unsetting notification here
				$notification = {
					text: 'Something went wrong',
					type: 'error'
				};
			}, 150);
			return;
		}

		if (!info?.login_hint && session) {
			await web3ModalSign.disconnect({
				topic: session.topic
			});
		}
		session = await web3ModalSign.connect({
			requiredNamespaces: {
				eip155: {
					methods: ['personal_sign'],
					chains: ['eip155:1'],
					events: []
				}
			}
		});
		const address = getAddressFromAccount(session.namespaces.eip155.accounts[0]);
		ethereumProgressModal = 'walletconnect';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign...')
			}
		];
		if (info?.login_hint && info?.login_hint.toLowerCase() !== address.toLowerCase()) {
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Expected ({address})', {
						values: {
							address: trimEthAddress(info.login_hint)
						}
					}),
					type: 'error',
					status: address
				}
			];
			return;
		}
		//TODO WalletConnect v2 bug: https://github.com/wagmi-dev/wagmi/issues/2631
		setTimeout(() => {
			continueWalletConnectSigning(address);
		}, 1000);
	}

	async function continueWalletConnectSigning(address) {
		let challenge, signature;
		const slug = session.peer.metadata.name.replace(/ /g, '-').toLowerCase();
		try {
			const res = await postLoginEth(address);
			logPlausibleEvent({ u: `/start/login/ethereum/walletconnect/${slug}`, n: 'action' });
			challenge = res.challenge;
		} catch (err) {
			console.error(err);
			$notification = {
				text: 'Something went wrong',
				type: 'error'
			};
			return;
		}

		//New User Release Funnel
		if (window.isWalletAuthorizeApp) {
			const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
				sessionStorage.az_release_funnel
			);
			const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_start');
			//session funnel state is valid and not already sent + is authorize app
			if (
				indexOfCurrentFunnelStep !== -1 &&
				indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
				window.isWalletAuthorizeApp
			) {
				const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
					'client_id'
				);
				const isRecommendedProvider = recommendedProviders.includes(slug);
				const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
					'redirect_uri'
				);
				let redirect;
				try {
					redirect = new URL(redirect_uri)?.hostname;
				} catch (err) {
					console.error(err);
				}
				await logPlausibleEvent({
					n: 'AZ Login Start',
					p: { client_id, provider: slug, recommended_provider: isRecommendedProvider, redirect },
					u: '/login'
				});
				sessionStorage.setItem('az_release_funnel', 'az_login_start');
			}
		}

		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				status: $_('Waiting to sign...')
			}
		];
		try {
			signature = await web3ModalSign.request({
				topic: session.topic,
				chainId: 'eip155:1',
				request: {
					method: 'personal_sign',
					params: [challenge, address]
				}
			});
			ethereumProgressNotifs[1] = {
				text: $_('Message signed'),
				type: 'success',
				status: $_('Logging you in...')
			};
		} catch (err) {
			console.info(err);
			$notification = {
				text: $_(`You've rejected the sign request`),
				type: 'error'
			};
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const icon =
			session.peer.metadata.icons[0] ||
			(session.peer.metadata?.url === 'https://metamask.io/'
				? 'https://cdn.hello.coop/images/metamask.svg'
				: 'https://cdn.hello.coop/images/ethereum.svg');
		const body = {
			signature,
			address,
			icon,
			name: session.peer.metadata.name
		};

		try {
			const res = await postLoginEthChallenge(body);
			await logPlausibleEvent({
				u: `/login/ethereum/walletconnect/${slug}`,
				n: 'action'
			});
			$data = await getData();

			//New User Release Funnel
			if (window.isWalletAuthorizeApp) {
				const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
					sessionStorage.az_release_funnel
				);
				const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_success');
				//session funnel state is valid and not already sent + is authorize app
				if (
					indexOfCurrentFunnelStep !== -1 &&
					indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
					window.isWalletAuthorizeApp
				) {
					const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
						'client_id'
					);
					const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
						'redirect_uri'
					);
					let redirect;
					try {
						redirect = new URL(redirect_uri)?.hostname;
					} catch (err) {
						console.error(err);
					}
					if (
						!$data?.uri &&
						!$data?.response_mode &&
						$data?.isNewUser &&
						!$data?.release?.previous
					) {
						//existing user: not auto-flow + isNewUser + not rerelease
						await logPlausibleEvent({
							n: 'AZ Login Success',
							p: { client_id, provider: slug, redirect },
							u: '/login'
						});
						sessionStorage.setItem('az_release_funnel', 'az_login_success');
					} else {
						await logPlausibleEvent({
							n: 'AZ Existing User',
							p: { client_id, provider: slug, redirect },
							u: '/login'
						});
						sessionStorage.removeItem('az_release_funnel');
					}
				}
			}

			if (!res?.uri && !res?.response_mode) {
				push('/');
			} else {
				$showSpinner = true;
				handleConsentResponse(res);
			}
		} catch (err) {
			console.error(err);
			const json = await err.json();
			if (err.status === 403) {
				if (json?.error?.message === 'ACCESS_DENIED') {
					$notification = {
						text: 'User does not have access to Dev Redirect URIs',
						type: 'error'
					};
				}
			}
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
		}
	}

	let showAllProviders = false;

	let passkeyLoginAjax = false;
	async function passkeyLogin() {
		try {
			passkeyLoginAjax = true;
			const challenge = await getLoginChallenge();
			const signedChallenge = await startAuthentication(challenge);
			const webAuthnLoginRes = await postLoginChallenge(signedChallenge);
			if (!webAuthnLoginRes?.verified) throw webAuthnLoginRes;
			if (window.isWalletAuthorizeApp) {
				$data = await getConsent();
			} else {
				$data = await getProfile();
			}
			return replaceRoute('/');
		} catch (err) {
			if (err.status) {
				//no passkeys found
				dispatch('passkeyLoginFail');
				console.error(err);
			} else {
				$notification = {
					text: 'Authentication cancelled',
					type: 'error'
				};
			}
		} finally {
			passkeyLoginAjax = false;
		}
	}
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
	<EthereumProgressModal
		notifications={ethereumProgressNotifs}
		on:cancel={() => {
			ethereumProgressNotifs = [];
			ethereumProgressModal = null;
		}}
		on:ok={(e) => {
			if (ethereumProgressModal === 'extension') {
				continueEthExtensionSigning(e.detail);
			} else if (ethereumProgressModal === 'walletconnect') {
				continueWalletConnectSigning(e.detail);
			}
		}}
	/>
{/if}

{#if login_hint?.email}
	<div class="relative h-auto btn-border rounded-md hover-none px-4 pt-4">
		{#if login_hint.provider?.slug}
			<LoginProvider
				login={true}
				on:click={() =>
					continueWithProvider(login_hint.provider.slug, { login_hint: login_hint.email })}
				provider={{
					slug: login_hint.provider.slug,
					user_name: login_hint.email
				}}
				prefix="Continue with"
			/>

			<span class="block text-lg text-center my-3 uppercase">{$_('Or')}</span>
		{/if}

		<button class="mb-2 w-full flex items-center justify-start">
			<MailIcon size="lg" />
			<div
				class="flex flex-col {$locale && $locale.startsWith('ar')
					? 'text-right mr-4'
					: 'text-left ml-4'}"
			>
				<span>
					{$_('Continue with Email')}
				</span>
				<span>{login_hint.email}</span>
			</div>
		</button>

		<div class="pb-4 pt-1">
			<VerifyEmail
				email={login_hint.email}
				login
				disabled
				on:success={contactLoginSuccess}
				on:error={handleContactError}
			/>
		</div>
	</div>
{:else}
	{#if loggedInSubject}
		<h1 class="mt-6 relative flex items-center justify-center text-lg font-medium text-center">
			<span class="w-3/5">
				{#if $data.recovery?.length > 1}
					{$_('You logged in with one of your recovery providers')}
				{:else}
					{$_('You logged in with your only recovery provider')}
				{/if}
			</span>
			<span class="absolute right-0 inline-flex items-center">
				<HelpButton
					ariaLabel="Help"
					content={$_('You use recovery providers if you lose access to your preferred provider')}
					placement="top"
				/>
			</span>
		</h1>

		<section
			data-test="loggedin-provider-container"
			class="bg-[#DCDCDC] dark:bg-[#505050] -mx-2 p-2 rounded-md mt-2"
		>
			<span
				class="{$locale && $locale.startsWith('ar')
					? 'text-right'
					: 'text-left'} block text-sm font-medium">{$_('Logged in recovery provider')}</span
			>
			<div
				class="flex flex-col justify-center items-start mt-2 px-4 rounded-md text-charcoal dark:text-[#D4D4D4]"
			>
				<div class="inline-flex items-center gap-x-4">
					<span class="inline-flex items-center justify-center rounded-sm">
						{#if loggedInSubject.slug === 'ethereum' && loggedInSubject.wallet}
							<img
								src={loggedInSubject.wallet.icon}
								alt="{loggedInSubject.wallet.name} logo"
								class="w-9 max-h-[36px]"
							/>
						{:else}
							<img
								src="https://cdn.hello.coop/images/{loggedInSubject.slug}.svg"
								alt="{loggedInSubject.slug} logo"
								class="w-9 max-h-[36px] {['apple', 'twitter', 'email', 'phone'].includes(
									//These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
									loggedInSubject.slug
								)
									? 'invert dark:invert-20'
									: ''}"
							/>
						{/if}
					</span>
					<div
						class="{$locale && $locale.startsWith('ar') ? 'text-right' : 'text-left'} flex flex-col"
					>
						<span>
							{#if loggedInSubject.slug === 'ethereum'}
								{#if loggedInSubject.wallet}
									{loggedInSubject.wallet.name}
								{:else}
									Ethereum
								{/if}
							{:else if loggedInSubject.slug === 'email'}
								{$_('Email')}
							{:else if loggedInSubject.slug === 'phone'}
								{$_('Phone')}
							{:else}
								{getDisplay(loggedInSubject.slug)}
							{/if}
							({$_('Recovery Provider')})
						</span>
						<span class="mt-1">
							{#if loggedInSubject.user_name}
								{loggedInSubject.user_name}
							{:else if loggedInSubject.login_hint}
								{loggedInSubject.login_hint}
							{/if}
						</span>
					</div>
				</div>
			</div>
		</section>
	{:else if $data.preferred}
		<section>
			<div class="flex justify-between items-center mb-2">
				<h2
					class="block text-sm font-medium {$locale && $locale.startsWith('ar')
						? 'text-right'
						: 'text-left'}"
				>
					{$_('Log in with your preferred provider')}
				</h2>
			</div>
			<div class="space-y-2">
				{#each $data.preferred as preferred}
					{#if preferred.slug === 'email'}
						<div class="relative h-auto btn-border rounded-md hover-none px-4">
							<button class="h-16 w-full flex items-center justify-start">
								<MailIcon size="lg" />
								<div
									class="flex flex-col {$locale && $locale.startsWith('ar')
										? 'text-right mr-4'
										: 'text-left ml-4'}"
								>
									<span>
										{$_('Continue with Email')}
									</span>
									<span>{preferred.login_hint}</span>
								</div>
							</button>
							<div class="pb-3 pt-1">
								<VerifyEmail
									email={preferred.login_hint}
									login
									disabled
									on:success={contactLoginSuccess}
									on:error={handleContactError}
								/>
							</div>
						</div>
					{:else if preferred.slug === 'phone'}
						<div class="relative h-auto btn-border rounded-md hover-none px-4">
							<button class="h-16 w-full flex items-center justify-start">
								<PhoneIcon size="lg" />
								<div
									class="flex flex-col {$locale && $locale.startsWith('ar')
										? 'text-right mr-4'
										: 'text-left ml-4'}"
								>
									<span>
										{$_('Continue with Phone')}
									</span>
									<span>{preferred.login_hint}</span>
								</div>
							</button>
							<div class="pb-3 pt-1">
								<VerifyPhone
									phone={preferred.login_hint}
									login
									disabled
									on:success={contactLoginSuccess}
									on:error={handleContactError}
								/>
							</div>
						</div>
					{:else if preferred.slug === 'ethereum'}
						{#if window.ethereum}
							<div class="relative h-auto btn-border rounded-md hover-none p-2 space-y-2">
								<span class="mb-3 block text-left"
									>{preferred.wallet?.name || 'Ethereum'}
									{#if preferred.wallet?.display}
										<span use:tippy={{ content: preferred.login_hint, placement: 'top' }}>
											({preferred.wallet?.display})
										</span>
									{/if}
								</span>
								<LoginProvider
									on:ethereum={() => continueWithEthereumExtension(preferred)}
									provider={preferred}
									hideusername
									prefix="Continue with"
								/>
								<!-- Note: Uncomment to show WalletConnect -->
								<!-- <button
									class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
									on:click={() => continueWithWalletConnect(preferred)}
								>
									<div class="flex items-center gap-x-4">
										<img
											src="https://cdn.hello.coop/images/walletconnect.svg"
											alt="WalletConnect"
											class="w-4.5 max-h-[18px]"
										/>
										<span class="block text-left">
											{$_('Continue with {provider}', { values: { provider: 'WalletConnect' } })}
										</span>
									</div>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										class="ml-auto stroke-2 group-hover:stroke-3 h-4.5 transform text-charcoal dark:text-[#d4d4d4] opacity-80"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
									</svg>
								</button> -->
							</div>
						{/if}
					{:else}
						<LoginProvider
							login={true}
							on:click={() =>
								continueWithProvider(
									preferred.slug,
									{
										login_hint: preferred.login_hint
									},
									preferred.slug === 'mastodon' ? preferred.mastodonServer : null
								)}
							provider={preferred}
							prefix="Continue with"
						/>
					{/if}
				{/each}
			</div>

			{#if $data.preferred?.length}
				{#if (rememberPrompt && !['email', 'phone', 'ethereum'].includes($data.preferred[0].slug)) || ($data.recovery?.length > 1 && !loggedInSubject)}
					<div class="flex justify-between items-center my-2">
						{#if rememberPrompt && !['email', 'phone', 'ethereum'].includes($data.preferred[0].slug)}
							<div class="flex justify-center items-center gap-x-2">
								<input
									id="remember"
									type="checkbox"
									name="remember"
									on:click={toggleNoPrompt}
									checked={$data.noPrompt}
									class="form-checkbox"
								/>
								<label for="remember" class="text-sm text-left">{$_("Don't ask again")}</label>
							</div>
						{/if}
						{#if $data.recovery?.length > 1 && !loggedInSubject}
							<div class={$locale && $locale.startsWith('ar') ? 'mr-auto' : 'ml-auto'}>
								<button
									on:click={() => (state.button.show_recoveries = !state.button.show_recoveries)}
									class="text-sm focus:underline hover:underline {$locale &&
									$locale.startsWith('ar')
										? 'text-left'
										: 'text-right'}"
									>{state.button.show_recoveries
										? $_('Hide other ways to log in')
										: $_('Show other ways to log in')}</button
								>
							</div>
						{/if}
					</div>
				{/if}
			{/if}
		</section>
	{/if}

	{#if loggedInSubject && $data.recovery?.length === 1}
		<h1 class="relative flex items-center justify-center text-lg font-medium mt-6">
			<span class="w-3/4">
				{$_('You must have two or more recovery providers to recover your wallet')}
			</span>

			<span class="absolute right-0 inline-flex items-center">
				<HelpButton
					ariaLabel="Help"
					content={$_('You can add recovery providers at wallet.hello.coop')}
					placement="top"
				/>
			</span>
		</h1>
		<h1 class="relative flex items-center justify-center text-lg font-medium mt-6">
			<span class="w-3/5">{$_('You can only log in with your preferred provider')}</span>
			<span class="absolute right-0 inline-flex items-center">
				<HelpButton
					ariaLabel="Help"
					content={$_(
						'This is your preferred way to log in. You can change this at wallet.hello.coop'
					)}
					placement="top"
				/>
			</span>
		</h1>
		<section class="mt-4 space-y-2">
			{#each $data.preferred as preferred}
				{#if preferred.slug === 'email'}
					<div class="relative h-auto btn-border rounded-md hover-none px-4">
						<button class="h-16 w-full flex items-center justify-start">
							<MailIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'text-right mr-4'
									: 'text-left ml-4'}"
							>
								<span>
									{$_('Continue with Email')}
								</span>
								<span>{preferred.login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyEmail
								email={preferred.login_hint}
								login
								disabled
								on:success={contactLoginSuccess}
								on:error={handleContactError}
							/>
						</div>
					</div>
				{:else if preferred.slug === 'phone'}
					<div class="relative h-auto btn-border rounded-md hover-none px-4">
						<button class="h-16 w-full flex items-center justify-start">
							<PhoneIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'text-right mr-4'
									: 'text-left ml-4'}"
							>
								<span>
									{$_('Continue with Phone')}
								</span>
								<span>{preferred.login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyPhone
								phone={preferred.login_hint}
								login
								disabled
								on:success={contactLoginSuccess}
								on:error={handleContactError}
							/>
						</div>
					</div>
				{:else if preferred.slug === 'ethereum'}
					<div class="relative h-auto btn-border rounded-md hover-none p-2 space-y-2">
						<span class="mb-3 block text-left"
							>{preferred.wallet?.name || 'Ethereum'}
							{#if preferred.wallet?.display}
								<span use:tippy={{ content: preferred.login_hint, placement: 'top' }}>
									({preferred.wallet?.display})
								</span>
							{/if}
						</span>
						{#if preferred.slug === 'ethereum' && window.ethereum}
							<LoginProvider
								on:ethereum={() => continueWithEthereumExtension(preferred)}
								provider={preferred}
								hideusername
								prefix="Continue with"
							/>
						{/if}
						<!-- Note: Uncomment to show WalletConnect -->
						<!-- <button
							class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
							on:click={() => continueWithWalletConnect(preferred)}
						>
							<div class="flex items-center gap-x-4">
								<img
									src="https://cdn.hello.coop/images/walletconnect.svg"
									alt="WalletConnect"
									class="w-4.5 max-h-[18px]"
								/>
								<span class="block text-left">
									{$_('Continue with {provider}', { values: { provider: 'WalletConnect' } })}
								</span>
							</div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="ml-auto stroke-2 group-hover:stroke-3 h-4.5 transform text-charcoal dark:text-[#d4d4d4] opacity-80"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
							</svg>
						</button> -->
					</div>
				{:else}
					<LoginProvider
						login={true}
						on:click={() =>
							continueWithProvider(
								preferred.slug,
								{
									login_hint: preferred.login_hint
								},
								preferred.slug === 'mastodon' ? preferred.mastodonServer : null
							)}
						provider={preferred}
						prefix="Continue with"
					/>
				{/if}
			{/each}
		</section>
	{/if}

	{#if loggedInSubject && $data.recovery?.length > 1}
		<section class="text-center mt-6">
			<h1 class="relative flex items-center justify-center text-lg font-medium mb-4">
				{$_('Log in with your preferred provider')}
				<span class="absolute right-0 inline-flex items-center">
					<HelpButton
						ariaLabel="Help"
						content={$_(
							'This is your preferred way to log in. You can change this at wallet.hello.coop'
						)}
						placement="top"
					/>
				</span>
			</h1>
			<div class="space-y-2">
				{#each $data.preferred as preferred}
					{#if preferred.slug === 'email'}
						<div class="relative h-auto btn-border rounded-md hover-none px-4">
							<button class="h-16 w-full flex items-center justify-start">
								<MailIcon size="lg" />
								<div
									class="flex flex-col {$locale && $locale.startsWith('ar')
										? 'text-right mr-4'
										: 'text-left ml-4'}"
								>
									<span>
										{$_('Continue with Email')}
									</span>
									<span>{preferred.login_hint}</span>
								</div>
							</button>
							<div class="pb-3 pt-1">
								<VerifyEmail
									email={preferred.login_hint}
									login
									disabled
									on:success={contactLoginSuccess}
									on:error={handleContactError}
								/>
							</div>
						</div>
					{:else if preferred.slug === 'phone'}
						<div class="relative h-auto btn-border rounded-md hover-none px-4">
							<button class="h-16 w-full flex items-center justify-start">
								<PhoneIcon size="lg" />
								<div
									class="flex flex-col {$locale && $locale.startsWith('ar')
										? 'text-right mr-4'
										: 'text-left ml-4'}"
								>
									<span>
										{$_('Continue with Phone')}
									</span>
									<span>{preferred.login_hint}</span>
								</div>
							</button>
							<div class="pb-3 pt-1">
								<VerifyPhone
									phone={preferred.login_hint}
									login
									disabled
									on:success={contactLoginSuccess}
									on:error={handleContactError}
								/>
							</div>
						</div>
					{:else if preferred.slug === 'ethereum'}
						<div class="relative h-auto btn-border rounded-md hover-none p-2 space-y-2">
							<span class="mb-3 block text-left"
								>{preferred.wallet?.name || 'Ethereum'}
								{#if preferred.wallet?.display}
									<span use:tippy={{ content: preferred.login_hint, placement: 'top' }}>
										({preferred.wallet?.display})
									</span>
								{/if}
							</span>
							{#if preferred.slug === 'ethereum' && window.ethereum}
								<LoginProvider
									on:ethereum={() => continueWithEthereumExtension(preferred)}
									provider={preferred}
									hideusername
									prefix="Continue with"
								/>
							{/if}
							<!-- Note: Uncomment to show WalletConnect -->
							<!-- <button
								class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
								on:click={() => continueWithWalletConnect(preferred)}
							>
								<div class="flex items-center gap-x-4">
									<img
										src="https://cdn.hello.coop/images/walletconnect.svg"
										alt="WalletConnect"
										class="w-4.5 max-h-[18px]"
									/>
									<span class="block text-left">
										{$_('Continue with {provider}', { values: { provider: 'WalletConnect' } })}
									</span>
								</div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="ml-auto stroke-2 group-hover:stroke-3 h-4.5 transform text-charcoal dark:text-[#d4d4d4] opacity-80"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
								</svg>
							</button> -->
						</div>
					{:else}
						<LoginProvider
							login={true}
							on:click={() =>
								continueWithProvider(
									preferred.slug,
									{
										login_hint: preferred.login_hint
									},
									preferred.slug === 'mastodon' ? preferred.mastodonServer : null
								)}
							provider={preferred}
							prefix="Continue with"
						/>
					{/if}
				{/each}
			</div>
			<h1 class="text-xl font-medium mt-6 mb-4 block uppercase">{$_('Or')}</h1>
		</section>
	{/if}

	{#if ($data.recovery?.length > 1 && loggedInSubject) || ($data.recovery?.length && state.button.show_recoveries)}
		<section
			data-test="recovery-providers-container"
			class="bg-[#DCDCDC] dark:bg-[#505050] -mx-2 p-2 rounded-md mt-3"
		>
			{#if !loggedInSubject}
				<h2
					class="{$locale && $locale.startsWith('ar')
						? 'text-right'
						: 'text-left'} block text-sm font-medium mb-2"
				>
					{$_('Or log in with two recovery providers')}
				</h2>
			{:else}
				<h2 class="relative flex items-center justify-center text-center text-lg font-medium mb-4">
					<span class="w-3/4">
						{#if $data.recovery?.filter((i) => i.id !== loggedInSubject.id).length === 1}
							{$_('Complete logging in with your second recovery provider')}
						{:else}
							{$_('Complete logging in with a second recovery provider')}
						{/if}
					</span>

					<span class="absolute right-0 inline-flex items-center">
						<HelpButton
							ariaLabel="Help"
							content={$_(
								'You need to log in with two recovery providers in order to access your wallet'
							)}
							placement="top"
						/>
					</span>
				</h2>
			{/if}
			<div class="space-y-2">
				{#if loggedInSubject}
					<!-- Filter out logged in subject -->
					{#each $data.recovery?.filter((i) => i.id !== loggedInSubject.id) as recovery}
						{#if recovery.slug === 'email'}
							<div class="relative h-auto btn-border rounded-md hover-none px-4">
								<button class="h-16 w-full flex items-center justify-start">
									<MailIcon size="lg" />
									<div
										class="flex flex-col {$locale && $locale.startsWith('ar')
											? 'text-right mr-4'
											: 'text-left ml-4'}"
									>
										<span
											>{$_('Continue with {provider}', {
												values: {
													provider: $_('Email')
												}
											})}</span
										>
										<span>{recovery.login_hint}</span>
									</div>
								</button>
								<div class="pb-4 pt-1">
									<VerifyEmail
										email={recovery.login_hint}
										login
										disabled
										on:success={contactLoginSuccess}
										on:error={handleContactError}
									/>
								</div>
							</div>
						{:else if recovery.slug === 'phone'}
							<div class="relative h-auto btn-border rounded-md hover-none px-4">
								<button class="h-16 w-full flex items-center justify-start">
									<PhoneIcon size="lg" />
									<div
										class="flex flex-col {$locale && $locale.startsWith('ar')
											? 'text-right mr-4'
											: 'text-left ml-4'}"
									>
										<span>
											{$_('Continue with Phone')}
										</span>
										<span>{recovery.login_hint}</span>
									</div>
								</button>
								<div class="pb-4 pt-1">
									<VerifyPhone
										phone={recovery.login_hint}
										login
										disabled
										on:success={contactLoginSuccess}
										on:error={handleContactError}
									/>
								</div>
							</div>
						{:else if recovery.slug === 'ethereum'}
							{#if window.ethereum}
								<div class="relative h-auto btn-border rounded-md hover-none p-2 space-y-2">
									<span class="mb-3 block text-left"
										>{recovery.wallet?.name || 'Ethereum'}
										{#if recovery.wallet?.display}
											<span use:tippy={{ content: recovery.login_hint, placement: 'top' }}>
												({recovery.wallet?.display})
											</span>
										{/if}
									</span>
									<LoginProvider
										on:ethereum={() => continueWithEthereumExtension(recovery)}
										provider={recovery}
										hideusername
										prefix="Continue with"
									/>
									<!-- Note: Uncomment to show WalletConnect -->
									<!-- <button
										class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
										on:click={() => continueWithWalletConnect(recovery)}
									>
										<div class="flex items-center gap-x-4">
											<img
												src="https://cdn.hello.coop/images/walletconnect.svg"
												alt="WalletConnect"
												class="w-4.5 max-h-[18px]"
											/>
											<span class="block text-left">
												{$_('Continue with {provider}', { values: { provider: 'WalletConnect' } })}
											</span>
										</div>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="ml-auto stroke-2 group-hover:stroke-3 h-4.5 transform text-charcoal dark:text-[#d4d4d4] opacity-80"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
										</svg>
									</button> -->
								</div>
							{/if}
						{:else}
							<LoginProvider
								login={true}
								on:click={() =>
									continueWithProvider(
										recovery.slug,
										{
											login_hint: recovery.login_hint
										},
										recovery.slug === 'mastodon' ? recovery.mastodonServer : null
									)}
								provider={recovery}
								prefix="Continue with"
							/>
						{/if}
					{/each}
				{:else}
					{#each $data.recovery as recovery}
						{#if recovery.slug === 'email'}
							<div class="relative h-auto btn-border rounded-md hover-none px-4">
								<button class="h-16 w-full flex items-center justify-start">
									<MailIcon size="lg" />
									<div
										class="flex flex-col {$locale && $locale.startsWith('ar')
											? 'text-right mr-4'
											: 'text-left ml-4'}"
									>
										<span>
											{$_('Continue with Email')}
										</span>
										<span>{recovery.login_hint}</span>
									</div>
								</button>
								<div class="pb-4 pt-1">
									<VerifyEmail
										email={recovery.login_hint}
										login
										disabled
										on:success={contactLoginSuccess}
										on:error={handleContactError}
									/>
								</div>
							</div>
						{:else if recovery.slug === 'phone'}
							<div class="relative h-auto btn-border rounded-md hover-none px-4">
								<button class="h-16 w-full flex items-center justify-start">
									<PhoneIcon size="lg" />
									<div
										class="flex flex-col {$locale && $locale.startsWith('ar')
											? 'text-right mr-4'
											: 'text-left ml-4'}"
									>
										<span>
											{$_('Continue with Phone')}
										</span>
										<span>{recovery.login_hint}</span>
									</div>
								</button>
								<div class="pb-4 pt-1">
									<VerifyPhone
										phone={recovery.login_hint}
										login
										disabled
										on:success={contactLoginSuccess}
										on:error={handleContactError}
									/>
								</div>
							</div>
						{:else if recovery.slug === 'ethereum'}
							{#if window.ethereum}
								<div class="relative h-auto btn-border rounded-md hover-none p-2 space-y-2">
									<span class="mb-3 block text-left"
										>{recovery.wallet?.name || 'Ethereum'}
										{#if recovery.wallet?.display}
											<span use:tippy={{ content: recovery.login_hint, placement: 'top' }}>
												({recovery.wallet?.display})
											</span>
										{/if}
									</span>
									<LoginProvider
										on:ethereum={() => continueWithEthereumExtension(recovery)}
										provider={recovery}
										hideusername
										prefix="Continue with"
									/>
									<!-- Note: Uncomment to show WalletConnect -->
									<!-- <button
										class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
										on:click={() => continueWithWalletConnect(recovery)}
									>
										<div class="flex items-center gap-x-4">
											<img
												src="https://cdn.hello.coop/images/walletconnect.svg"
												alt="WalletConnect"
												class="w-4.5 max-h-[18px]"
											/>
											<span class="block text-left">
												{$_('Continue with {provider}', { values: { provider: 'WalletConnect' } })}
											</span>
										</div>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="ml-auto stroke-2 group-hover:stroke-3 h-4.5 transform text-charcoal dark:text-[#d4d4d4] opacity-80"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
										</svg>
									</button> -->
								</div>
							{/if}
						{:else}
							<LoginProvider
								login={true}
								on:click={() =>
									continueWithProvider(
										recovery.slug,
										{
											login_hint: recovery.login_hint
										},
										recovery.slug === 'mastodon' ? recovery.mastodonServer : null
									)}
								provider={recovery}
								prefix="Continue with"
							/>
						{/if}
					{/each}
				{/if}
			</div>
		</section>
	{/if}

	{#if rememberPrompt && loggedInSubject && $data.preferred?.length && !['email', 'phone', 'ethereum'].includes($data.preferred[0].slug)}
		<div class="flex justify-start items-center gap-x-2 my-6">
			<input
				id="remember"
				type="checkbox"
				name="remember"
				on:click={toggleNoPrompt}
				checked={$data.noPrompt}
				class="form-checkbox"
			/>
			<label for="remember" class="text-sm text-left">{$_("Don't ask again")}</label>
		</div>
	{/if}

	<!-- Only show passkey login if device supports passkey login and user has passkey -->
	{#if showPasskeyLogin && $data.hasPasskey}
		<section class="mt-4">
			<LoginProvider
				on:click={passkeyLogin}
				loading={passkeyLoginAjax}
				disabled={passkeyLoginAjax}
				provider={{ slug: 'passkey' }}
				label="Continue with Hellō Passkey"
			/>
		</section>
	{/if}

	{#if $data.preferred}
		<button
			data-test="not-you-btn"
			on:click={() => (state.button.show_all = !state.button.show_all)}
			class="mt-4 text-sm mx-auto block focus:underline hover:underline"
			>{state.button.show_all
				? $_('Hide other accounts')
				: $_('Not you? Log in with a different account')}</button
		>
	{/if}

	{#if !$data.preferred && !loggedInSubject}
		<section class="mt-2 w-full">
			{#if !$data.preferred && !loggedInSubject}
				<div data-test="above-fold-list" class="space-y-2 mb-2">
					{#each recommendedProviders as slug}
						{#if slug === 'email'}
							<Dropdown
								dataTest="email-btn"
								ariaLabel={$_('Continue with Email')}
								expanded={state.button.email.default}
								id="email-container"
								on:click={() => {
									state.button.email.default = !state.button.email.default;
									state.button.phone.default = false;
								}}
							>
								<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
									<MailIcon />
									<span class="block text-left" aria-hidden="true">
										{$_('Continue with Email')}
									</span>
								</div>
								{#if state.button.email.default}
									<div class="pb-3 pt-1 px-4" transition:slide|local>
										<VerifyEmail
											login
											recommendedProvider={true}
											on:success={contactLoginSuccess}
											on:error={handleContactError}
										/>
									</div>
								{/if}
							</Dropdown>
							<!-- {:else if slug === 'phone'}
							<Dropdown
								dataTest="phone-btn"
								ariaLabel={$_('Continue with Phone')}
								expanded={state.button.phone.default}
								id="phone-container"
								on:click={() => {
									state.button.phone.default = !state.button.phone.default;
									state.button.email.default = false;
								}}
							>
								<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
									<PhoneIcon />
									<span class="block text-left" aria-hidden="true">
										{$_('Continue with Phone')}
									</span>
								</div>
								{#if state.button.phone.default}
									<div class="pb-3 pt-1 px-4" transition:slide|local>
										<VerifyPhone
											login
											recommendedProvider={true}
											on:success={contactLoginSuccess}
											on:error={handleContactError}
										/>
									</div>
								{/if}
							</Dropdown> -->
						{:else if slug === 'ethereum'}
							{#if window.ethereum?.isMetaMask}
								<LoginProvider
									login
									provider={{ display: 'MetaMask', slug: 'metamask' }}
									prefix="Continue with"
									on:click={continueWithEthereumExtension}
								/>
							{:else if window.ethereum}
								<LoginProvider
									login
									provider={{ display: 'Ethereum', slug: 'ethereum' }}
									prefix="Continue with"
									on:click={continueWithEthereumExtension}
								/>
							{/if}
							<!-- Note: Uncomment to show WalletConnect -->
							<!-- <LoginProvider
								login
								provider={{ slug: 'walletconnect', display: 'WalletConnect' }}
								prefix="Continue with"
								on:click={continueWithWalletConnect}
							/> -->
						{:else if slug !== 'qrcode'}
							<LoginProvider
								login
								provider={{ slug }}
								prefix="Continue with"
								on:click={(e) => continueWithProvider(slug, null, e.detail)}
								on:ethereum={continueWithEthereumExtension}
								on:walletconnect={continueWithWalletConnect}
							/>
						{/if}
						{#if slug === 'qrcode' && window.isWalletAuthorizeApp && !$isRemoteAuthClient}
							<button
								class="group h-12 relative btn-background w-full flex items-center justify-start px-4"
								on:click={() => dispatch('qrcode')}
								disabled={continueWithQRCodeAjax}
							>
								{#if continueWithQRCodeAjax}
									<SpinnerIcon css="h-5.5 w-5.5 block mx-auto" />
								{:else}
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke-width="2"
										stroke="currentColor"
										class="h-4.5 transform opacity-80"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"
										/>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z"
										/>
									</svg>

									<span class="{$locale && $locale.startsWith('ar') ? 'mr-4' : 'ml-4'} truncate">
										{$_('Continue by scanning QR Code')}
									</span>

									<svg
										xmlns="http://www.w3.org/2000/svg"
										class="ml-auto stroke-2 group-hover:stroke-3 h-4.5 transform text-white dark:text-[#d4d4d4] opacity-80"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
									</svg>
								{/if}
							</button>
						{/if}
					{/each}
				</div>
			{/if}

			<section
				data-test="below-fold-list"
				class="bg-[#DCDCDC] dark:bg-[#505050] -mx-2 rounded-md mt-2"
			>
				<button
					data-test="show-below-fold-providers-btn"
					on:click={() => (showAllProviders = !showAllProviders)}
					class="w-full h-full px-6 group py-3 text-left flex items-center justify-between"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="stroke-2 group-hover:stroke-3 group-focus:stroke-3 h-4.5 transform text-charcoal dark:text-[#d4d4d4] opacity-80 invisible"
						class:rotate-180={showAllProviders}
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
					</svg>
					<span
						>{!showAllProviders ? 'Show other ways to log in' : 'Hide other ways to log in'}</span
					>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="stroke-2 group-hover:stroke-3 group-focus:stroke-3 h-4.5 transform text-charcoal dark:text-[#d4d4d4] opacity-80"
						class:rotate-180={showAllProviders}
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
					</svg></button
				>

				{#if showAllProviders}
					<div class="px-2 pb-3 pt-1" transition:slide|local>
						<LoginProviderGroup
							login
							prefix="Continue with"
							{continueWithQRCodeAjax}
							on:ethereum={continueWithEthereumExtension}
							on:walletconnect={continueWithWalletConnect}
							on:qrcode={() => dispatch('qrcode')}
							ignoreProviders={recommendedProviders}
							cryptoWallets={!recommendedProviders.includes('ethereum')}
						>
							{#if !recommendedProviders.includes('email')}
								<Dropdown
									dataTest="email-btn"
									ariaLabel={$_('Continue with Email')}
									expanded={state.button.email.default}
									id="email-container"
									on:click={() => {
										state.button.email.default = !state.button.email.default;
										state.button.phone.default = false;
									}}
								>
									<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
										<MailIcon />
										<span class="block text-left" aria-hidden="true">
											{$_('Continue with Email')}
										</span>
									</div>
									{#if state.button.email.default}
										<div class="pb-3 pt-1 px-4" transition:slide|local>
											<VerifyEmail
												login
												on:success={contactLoginSuccess}
												on:error={handleContactError}
											/>
										</div>
									{/if}
								</Dropdown>
							{/if}

							<!-- {#if !recommendedProviders.includes('phone')}
								<Dropdown
									dataTest="phone-btn"
									ariaLabel={$_('Continue with Phone')}
									expanded={state.button.phone.default}
									id="phone-container"
									on:click={() => {
										state.button.phone.default = !state.button.phone.default;
										state.button.email.default = false;
									}}
								>
									<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
										<PhoneIcon />
										<span class="block text-left" aria-hidden="true">
											{$_('Continue with Phone')}
										</span>
									</div>
									{#if state.button.phone.default}
										<div class="pb-3 pt-1 px-4" transition:slide|local>
											<VerifyPhone
												login
												on:success={contactLoginSuccess}
												on:error={handleContactError}
											/>
										</div>
									{/if}
								</Dropdown>
							{/if} -->
						</LoginProviderGroup>
					</div>
				{/if}
			</section>
		</section>
	{/if}

	{#if $data.preferred && state.button.show_all}
		<section class="mt-2 w-full">
			<LoginProviderGroup
				login
				prefix="Continue with"
				on:ethereum={continueWithEthereumExtension}
				on:walletconnect={continueWithWalletConnect}
				{continueWithQRCodeAjax}
				on:qrcode={() => dispatch('qrcode')}
			>
				<Dropdown
					dataTest="email-btn"
					ariaLabel={$_('Continue with Email')}
					expanded={state.button.email.default}
					id="email-container"
					on:click={() => {
						state.button.email.default = !state.button.email.default;
						state.button.phone.default = false;
					}}
				>
					<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
						<MailIcon />
						<span class="block text-left" aria-hidden="true">
							{$_('Continue with Email')}
						</span>
					</div>
					{#if state.button.email.default}
						<div class="pb-3 pt-1 px-4" transition:slide|local>
							<VerifyEmail login on:success={contactLoginSuccess} on:error={handleContactError} />
						</div>
					{/if}
				</Dropdown>

				<!-- <Dropdown
					dataTest="phone-btn"
					ariaLabel={$_('Continue with Phone')}
					expanded={state.button.phone.default}
					id="phone-container"
					on:click={() => {
						state.button.phone.default = !state.button.phone.default;
						state.button.email.default = false;
					}}
				>
					<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
						<PhoneIcon />
						<span class="block text-left" aria-hidden="true">
							{$_('Continue with Phone')}
					</span>
					</div>
					{#if state.button.phone.default}
						<div class="pb-3 pt-1 px-4" transition:slide|local>
							<VerifyPhone login on:success={contactLoginSuccess} on:error={handleContactError} />
						</div>
					{/if}
				</Dropdown> -->
			</LoginProviderGroup>
		</section>
	{/if}
{/if}
