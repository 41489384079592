<script>
	import { createEventDispatcher } from 'svelte';
	import { fade, fly } from 'svelte/transition';

	export let dataTest = '';

	const dispatch = createEventDispatcher();
</script>

<svelte:window
	on:keydown={(e) => {
		if (e.key === 'Escape') {
			dispatch('close');
		}
	}}
/>

<div class="relative z-50" role="dialog" aria-modal="true">
	<div
		class="fixed inset-0 bg-charcoal bg-opacity-80 transition-opacity"
		aria-hidden="true"
		in:fade|local={{ duration: 300 }}
		out:fade|local={{ duration: 200 }}
	/>
	<div class="fixed inset-0 z-10 w-full overflow-y-auto" transition:fly|local={{ y: 20 }}>
		<div class="flex min-h-full w-full justify-center p-4 text-center items-center sm:p-0">
			<div
				class="relative transform w-full overflow-hidden rounded-lg transition-all sm:my-14 sm:max-w-md p-1"
			>
				<div
					data-test={dataTest}
					class="bg-white rounded-lg dark:bg-[#151515] border border-white dark:border-[#808080] px-4 pb-4 pt-5 sm:p-6 sm:pb-4 flex flex-col items-center"
				>
					<slot />
				</div>
			</div>
		</div>
	</div>
</div>
