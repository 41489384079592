<script>
	import logins from '../../../../svr/providers/logins.json';
	import { slide } from 'svelte/transition';
	import { _, locale } from 'svelte-i18n';
	import { createEventDispatcher } from 'svelte';
	import Dropdown from './Dropdown.svelte';

	/*
	List of known mastodon servers and servers that use Hellō for login.
	This list is checked before calling nodeinfo endpoint.
	Servers that use Hellō for login are not allowed for login.
	*/
	import mastodonSeed from '../mastodon.seed.json';
	import { getDisplay, isValidDomain } from '../utils/helper';
	import { getMastodonDiscovery } from '../utils/api-calls';
	import SpinnerIcon from './icon/SpinnerIcon.svelte';

	const dispatch = createEventDispatcher();

	export let login = false;
	export let mastodonExpanded = false;
	export let hideMastodonDropdown = false;
	export let autofocus = false;
	export let provider = {};
	export let prefix = '';
	export let hideusername = false;
	export let label = '';
	export let loading = false;
	export let disabled = false;
	export let strikethrough = false;

	let value = '';
	let showInvalidDomainErrorText = false;
	let showMastodonServerNotFoundErrorText = false;
	let knownLoginErrorText = false;
	let mastodonServerButtonDisabled = true;
	let checkMastodonServerAjax = false;

	$: {
		if (mastodonExpanded) {
			//reset all states when expanded mastodon dropdown
			value = '';
			checkMastodonServerAjax =
				showInvalidDomainErrorText =
				showMastodonServerNotFoundErrorText =
				knownLoginErrorText =
					false;
		}
	}

	let timer;
	async function handleMastodonServerInput() {
		checkMastodonServerAjax =
			showInvalidDomainErrorText =
			showMastodonServerNotFoundErrorText =
			knownLoginErrorText =
				false;
		mastodonServerButtonDisabled = true;
		const server = value;

		if (timer) clearTimeout(timer);
		if (Object.keys(mastodonSeed).includes(value)) {
			if (login && mastodonSeed[value].hello_login && !window.isWalletMastodonApp) {
				knownLoginErrorText = true;
			} else {
				mastodonServerButtonDisabled = false;
			}
		} else {
			timer = setTimeout(async () => {
				if (!server.length) return;
				const invalidDomain = !isValidDomain(server);
				if (invalidDomain) {
					showInvalidDomainErrorText = true;
					return;
				}
				checkMastodonServerAjax = true;
				let discoRes;
				try {
					discoRes = await getMastodonDiscovery(server);
					if (server !== value) return;
					if (discoRes?.software?.name === 'mastodon') {
						if (discoRes?.metadata?.hello) {
							knownLoginErrorText = true;
						} else {
							mastodonServerButtonDisabled = false;
						}
					} else {
						showMastodonServerNotFoundErrorText = true;
						mastodonServerButtonDisabled = true;
					}
				} catch (err) {
					mastodonServerButtonDisabled = true;
					if (err.status === 400) showInvalidDomainErrorText = true;
					if (err.status === 500 || err.status === 502) showMastodonServerNotFoundErrorText = true;
				}
				checkMastodonServerAjax = false;
			}, 650);
		}
	}
	$: display = provider.display || logins.find((i) => i.slug === provider.slug)?.display;
</script>

{#if provider.slug === 'mastodon' && !provider.user_name}
	{#if hideMastodonDropdown}
		<form on:submit|preventDefault={() => dispatch('click', value)} transition:slide|local>
			<!-- svelte-ignore a11y-autofocus -->
			<input
				autofocus
				type="text"
				name="mastodon-server"
				id="mastodon-server"
				bind:value
				on:input={handleMastodonServerInput}
				autocapitalize="off"
				placeholder="enter your Mastodon server (mastodon.example)"
				class="px-[16px] sm:px-[18px] w-full h-12 bg-transparent"
			/>
			{#if showInvalidDomainErrorText}
				<span
					class="text-red-500 text-left mt-2 block"
					data-test="mastodon-input-error"
					transition:slide>{$_('Invalid domain')}</span
				>
			{:else if showMastodonServerNotFoundErrorText}
				<span
					class="text-red-500 text-left mt-2 block"
					data-test="mastodon-input-error"
					transition:slide>{$_('Mastodon server not found')}</span
				>
			{:else if knownLoginErrorText}
				<span
					class="text-red-500 text-left block mt-2"
					data-test="mastodon-input-error"
					transition:slide>{$_('This server uses Hellō for login')}</span
				>
			{:else if checkMastodonServerAjax}
				<div class="flex justify-start items-center mt-2" transition:slide>
					<SpinnerIcon css="h-5 w-5 text-charcoal dark:text-white" />
					<span class="text-left block opacity-80 ml-2">{$_('Checking')}</span>
				</div>
			{/if}
			<button
				data-test="mastodon-continue-btn"
				class="btn-background w-full mt-4 flex items-center justify-center"
				disabled={mastodonServerButtonDisabled}
			>
				Continue
			</button>
		</form>
	{:else}
		<Dropdown
			dataTest="mastodon-btn"
			ariaLabel={$_(`${prefix} {provider}`, { values: { provider: display } })}
			expanded={mastodonExpanded}
			on:click={() => {
				mastodonExpanded = !mastodonExpanded;
			}}
		>
			<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
				<img
					src="https://cdn.hello.coop/images/mastodon.svg"
					alt="Mastodon"
					class="w-4.5 max-h-[18px]"
				/>
				<span class="block text-left" aria-hidden="true">
					{$_(`${prefix} {provider}`, { values: { provider: display } })}
				</span>
			</div>
			{#if mastodonExpanded}
				<form
					class="pb-3 pt-1 px-4"
					on:submit|preventDefault={() => dispatch('click', value)}
					transition:slide|local
				>
					<!-- svelte-ignore a11y-autofocus -->
					<input
						autofocus
						type="text"
						name="mastodon-server"
						id="mastodon-server"
						bind:value
						on:input={handleMastodonServerInput}
						autocapitalize="off"
						placeholder="enter your Mastodon server (mastodon.example)"
						class="px-[16px] sm:px-[18px] w-full h-12 bg-transparent"
					/>
					{#if showInvalidDomainErrorText}
						<span
							class="text-red-500 text-left mt-2 block"
							data-test="mastodon-input-error"
							transition:slide>{$_('Invalid domain')}</span
						>
					{:else if showMastodonServerNotFoundErrorText}
						<span
							class="text-red-500 text-left mt-2 block"
							data-test="mastodon-input-error"
							transition:slide>{$_('Mastodon server not found')}</span
						>
					{:else if knownLoginErrorText}
						<span
							class="text-red-500 text-left block mt-2"
							data-test="mastodon-input-error"
							transition:slide>{$_('This server uses Hellō for login')}</span
						>
					{:else if checkMastodonServerAjax}
						<div class="flex justify-start items-center mt-2" transition:slide>
							<SpinnerIcon css="h-5 w-5 text-charcoal dark:text-white" />
							<span class="text-left block opacity-80 ml-2">{$_('Checking')}</span>
						</div>
					{/if}
					<button
						data-test="mastodon-continue-btn"
						class="btn-background w-full mt-4 flex items-center justify-center"
						disabled={mastodonServerButtonDisabled}
					>
						Continue
					</button>
				</form>
			{/if}
		</Dropdown>
	{/if}
{:else}
	<!-- svelte-ignore a11y-autofocus -->
	<button
		{autofocus}
		{disabled}
		data-test={`${provider.slug}-btn`}
		class="group disabled:opacity-50 btn-background disabled:cursor-not-allowed w-full relative overflow-hidden flex items-center justify-start px-4"
		class:h-18={provider.user_name && !hideusername}
		on:click={() => {
			if (provider.slug === 'ethereum') dispatch('ethereum');
			else dispatch('click');
		}}
	>
		{#if loading}
			<SpinnerIcon css="h-5 w-5 block mx-auto" />
		{:else}
			{#if provider.slug === 'ethereum'}
				<img
					src={provider.wallet?.icon || 'https://cdn.hello.coop/images/ethereum.svg'}
					alt={provider.wallet?.name || 'Ethereum'}
					class={provider.user_name && !hideusername
						? 'mt-0 w-9 max-h-[36px]'
						: 'w-4.5 max-h-[18px]'}
				/>
			{:else if provider.slug === 'passkey'}
				<svg class="mt-0.5 h-4.5 w-4.5" viewBox="0 0 651 634" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M237.333 1.06607C184.933 5.73273 136.533 39.7328 114.667 87.0661C96.7999 125.999 95.9999 169.866 112.667 209.866C121.467 230.933 140 254.933 158.267 269.066C210.133 308.933 279.867 311.466 334.133 275.333C373.2 249.333 397.867 205.866 400.267 158.666C401.867 127.466 394.933 99.7328 378.933 72.6661C370.8 58.7994 349.067 35.7327 335.467 26.6661C318 14.9327 295.867 5.9994 276.4 2.66607C268.267 1.33273 245.333 0.266065 237.333 1.06607Z"
						fill="currentColor"
					/>
					<path
						d="M511.466 186C498.133 189.067 490.133 191.867 479.6 197.467C438.533 219.2 414.933 261.733 417.6 309.067C419.6 345.733 443.066 383.067 475.733 401.333C482.8 405.333 483.333 405.867 483.333 410.4C483.333 413.2 483.6 453.333 483.733 499.6L484 584L509.066 608.933L534 634L575.6 592.267L617.333 550.667L592.4 525.733L567.333 500.667L592.266 475.733L617.333 450.667L596.8 430.133L576.266 409.6L585.2 405.333C603.333 396.667 622 380 632.933 362.667C645.2 343.067 650.133 325.067 650 300.533C650 278.8 647.333 267.2 638.133 248.667C623.6 219.2 598.4 198 566.666 188.667C551.6 184.133 525.2 182.933 511.466 186ZM544.8 236.267C551.6 238.267 560 245.467 563.733 252.533C567.733 260.133 567.733 274.4 563.733 282.133C557.733 293.333 546.8 300 534 300C506.533 300 491.2 269.467 507.733 247.733C516.266 236.4 530.8 232 544.8 236.267Z"
						fill="currentColor"
					/>
					<path
						d="M170.667 352.799C95.9999 364.265 34.3999 416.265 10.7999 487.599C2.53324 512.665 1.73324 519.332 1.06657 570.265L0.399902 617.332H217.2H434V525.999V434.665L418.933 419.599C403.733 404.532 393.333 390.532 386.267 375.732C382.667 368.265 382.533 367.999 372 363.865C359.2 358.799 340.8 353.999 326.8 352.132C310.933 349.865 185.867 350.399 170.667 352.799Z"
						fill="currentColor"
					/>
				</svg>
			{:else}
				<img
					src="https://cdn.hello.coop/images/{provider.slug}.svg"
					alt={display}
					class={provider.user_name && !hideusername
						? 'mt-0 w-9 max-h-[36px]'
						: 'w-4.5 max-h-[18px]'}
				/>
			{/if}

			{#if provider.user_name}
				<div
					class="{$locale && $locale.startsWith('ar')
						? 'mr-4 text-right'
						: 'ml-4 text-left'} truncate"
				>
					<span class="block">
						{#if provider.slug === 'ethereum'}
							{$_(`${prefix} {provider}`, { values: { provider: $_('extension') } })}
						{:else}
							{$_(`${prefix} {provider}`, { values: { provider: getDisplay(provider.slug) } })}
						{/if}
					</span>

					{#if !hideusername}
						<span class="block truncate">
							{#if provider.slug === 'ethereum'}
								{provider.wallet?.name || 'Ethereum'}
							{/if}

							{#if provider.slug === 'mastodon'}
								@{provider.user_name}@{provider.mastodonServer}
							{:else}
								{provider.user_name}
							{/if}
						</span>
					{/if}
				</div>
			{:else}
				<span
					class:line-through={strikethrough}
					class="block truncate {$locale && $locale.startsWith('ar')
						? 'mr-4 text-right'
						: 'ml-4 text-left'}"
				>
					{#if label}
						{label}
					{:else}
						{$_(`${prefix} {provider}`, {
							values: {
								provider: display
							}
						})}
					{/if}
				</span>
			{/if}

			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="flex-shrink-0 {$locale && $locale.startsWith('ar')
					? 'mr-auto rotate-90'
					: 'ml-auto -rotate-90'} h-4.5 opacity-80 transform text-white dark:text-[#d4d4d4] group-focus:stroke-3 group-hover:stroke-3 {provider.user_name &&
				!hideusername
					? 'mb-5.5'
					: ''}"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
			</svg>
		{/if}
	</button>
{/if}
