<script>
	import { _ } from 'svelte-i18n';
	import { data, mergeAccounts, showSpinner, notification } from '../../stores.js';
	import {
		getDisplay,
		logPlausibleEvent,
		trimEthAddress,
		getWallet,
		handleConsentResponse,
		updateAccounts
	} from '../../utils/helper.js';
	import LoginProvider from '../LoginProvider.svelte';
	import {
		getConsent,
		getProfile,
		postLoginProvider,
		postLoginEth,
		postLoginEthChallenge
	} from '../../utils/api-calls.js';
	import VerifyEmail from '../VerifyEmail.svelte';
	import VerifyPhone from '../VerifyPhone.svelte';
	import MailIcon from '../icon/MailIcon.svelte';
	import PhoneIcon from '../icon/PhoneIcon.svelte';
	import { locale } from 'svelte-i18n';
	import tippy from 'sveltejs-tippy';
	import EthereumProgressModal from '../modal/EthereumProgressModal.svelte';
	import { location } from 'svelte-spa-router';
	import { getAddressFromAccount } from '@walletconnect/utils';
	import { WalletConnectModalSign } from '@walletconnect/modal-sign-html';
	import { WALLETCONNECT_CONFIG } from '../../constants.js';
	import SpinnerIcon from '../icon/SpinnerIcon.svelte';
	import FullPageModal from './FullPageModal.svelte';

	let web3ModalSign;

	let mergeAjax = false;
	let ethereumProgressModal;
	let ethereumProgressNotifs = [];

	async function confirmMerge() {
		try {
			mergeAjax = true;
			const res = await fetch('/api/v1/merge', { method: 'PUT' });
			if (!res.ok) throw res;
			await hydrateData();
		} catch (err) {
			mergeAjax = false;
			console.error(err);
			$notification = {
				text: 'Something went wrong',
				type: 'error'
			};
		} finally {
			$mergeAccounts = null;
		}
	}

	async function cancelMerge() {
		try {
			const res = await fetch('/api/v1/merge', { method: 'DELETE' });
			if (!res.ok) throw res;

			if (window.isWalletMastodonApp && ['/twitter', '/github'].includes($location)) {
				window.location.reload();
			}
		} catch (err) {
			console.error(err);
			$notification = {
				text: 'Something went wrong',
				type: 'error'
			};
		} finally {
			$mergeAccounts = null;
		}
	}

	// We need the modal to be hidden if redirect for login has started.
	// We do not want to set $mergeAccounts to null because we still need the state
	// if post login redirect call api fails.
	let hideModal = false;

	async function continueWithProvider(slug, body, server) {
		try {
			hideModal = true;
			$showSpinner = true;
			const { redirect } = await postLoginProvider({
				slug,
				body,
				server,
				access: window.isWalletMastodonApp && ['mastodon', 'twitter'].includes(slug)
			});
			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			hideModal = false;
			console.error(err);
		}
	}

	async function contactLoginSuccess() {
		await hydrateData();
	}

	function handleContactError(err) {
		if (err?.detail?.status >= 500) {
			$notification = {
				text: $_('Something went wrong. Please try again later.'),
				type: 'error'
			};
			$mergeAccounts = null;
		}
	}

	async function hydrateData() {
		ethereumProgressModal = false;
		ethereumProgressNotifs = [];
		try {
			if (window.isWalletAuthorizeApp) {
				$data = await getConsent();
				if ($data.uri && $data.response_mode) return handleConsentResponse($data);
			} else if (window.isWalletApp || window.isWalletMastodonApp) {
				//getProfile - is wallet app or mastodon
				$data = await getProfile();
				$data = updateAccounts($data); //add sortrank, sortlabel and unverified accounts to accounts array
			}

			$notification = {
				text: 'Wallets merged successfully',
				type: 'success'
			};

			if (window.isWalletMastodonApp && ['/twitter', '/github'].includes($location)) {
				window.location.reload();
			}

			$mergeAccounts = null;
		} catch (err) {
			console.error(err);
			return;
		}
	}

	let session;
	async function continueWithWalletConnect(info) {
		try {
			web3ModalSign = new WalletConnectModalSign(WALLETCONNECT_CONFIG);
		} catch (err) {
			console.error(err);
			setTimeout(() => {
				//tbd : remove timeout - something is unsetting notification here
				$notification = {
					text: 'Something went wrong',
					type: 'error'
				};
			}, 150);
			return;
		}

		if (!info?.login_hint && session) {
			await web3ModalSign.disconnect({
				topic: session.topic
			});
		}
		session = await web3ModalSign.connect({
			requiredNamespaces: {
				eip155: {
					methods: ['personal_sign'],
					chains: ['eip155:1'],
					events: []
				}
			}
		});
		const address = getAddressFromAccount(session.namespaces.eip155.accounts[0]);
		ethereumProgressModal = 'walletconnect';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign...')
			}
		];
		if (info?.login_hint && info?.login_hint.toLowerCase() !== address.toLowerCase()) {
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Expected ({address})', {
						values: {
							address: trimEthAddress(info.login_hint)
						}
					}),
					type: 'error',
					status: address
				}
			];
			return;
		}
		//TODO WalletConnect v2 bug: https://github.com/wagmi-dev/wagmi/issues/2631
		setTimeout(() => {
			continueWalletConnectSigning(address);
		}, 1000);
	}

	async function continueWithEthereumExtension(info) {
		const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
		ethereumProgressModal = 'extension';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign...')
			}
		];
		if (info?.login_hint && info?.login_hint.toLowerCase() !== address.toLowerCase()) {
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Expected ({address})', {
						values: {
							address: trimEthAddress(info.login_hint)
						}
					}),
					type: 'error',
					status: address
				}
			];
			return;
		}
		continueEthExtensionSigning(address);
	}

	async function continueEthExtensionSigning(address) {
		let challenge, signature;

		try {
			const res = await postLoginEth(address);
			logPlausibleEvent({ u: `/start/merge/ethereum/extension/${getWallet().slug}`, n: 'action' });
			challenge = res.challenge;
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					status: $_('Waiting to sign...')
				}
			];
		} catch (err) {
			console.error(err);
		}

		try {
			signature = await window.ethereum.request({
				method: 'personal_sign',
				params: [address, challenge]
			});
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Message signed'),
					type: 'success',
					status: 'Merging wallets...'
				}
			];
		} catch (err) {
			console.info(err);
			if (err.code === 4001) {
				$notification = {
					text: $_("You've rejected the sign request"),
					type: 'error'
				};
			} else {
				$notification = {
					text: $_('Something went wrong. Please try again later.'),
					type: 'error'
				};
			}
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const body = {
			signature,
			address,
			icon: getWallet().icon,
			name: getWallet().name
		};

		try {
			await postLoginEthChallenge(body);
			await logPlausibleEvent({ u: `/merge/ethereum/extension/${getWallet().slug}`, n: 'action' });
			await hydrateData();
		} catch (err) {
			console.error(err);
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
		}
	}

	async function continueWalletConnectSigning(address) {
		let challenge, signature;
		const slug = session.peer.metadata.name.replace(/ /g, '-').toLowerCase();
		try {
			const res = await postLoginEth(address);
			logPlausibleEvent({ u: `/start/merge/ethereum/walletconnect/${slug}`, n: 'action' });
			challenge = res.challenge;
		} catch (err) {
			console.error(err);
			$notification = {
				text: 'Something went wrong',
				type: 'error'
			};
			return;
		}
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				status: $_('Waiting to sign...')
			}
		];
		try {
			signature = await web3ModalSign.request({
				topic: session.topic,
				chainId: 'eip155:1',
				request: {
					method: 'personal_sign',
					params: [challenge, address]
				}
			});
			ethereumProgressNotifs[1] = {
				text: $_('Message signed'),
				type: 'success',
				status: 'Merging wallets...'
			};
		} catch (err) {
			console.info(err);
			$notification = {
				text: $_(`You've rejected the sign request`),
				type: 'error'
			};
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const icon =
			session.peer.metadata.icons[0] ||
			(session.peer.metadata?.url === 'https://metamask.io/'
				? 'https://cdn.hello.coop/images/metamask.svg'
				: 'https://cdn.hello.coop/images/ethereum.svg');
		const body = {
			signature,
			address,
			icon,
			name: session.peer.metadata.name
		};

		try {
			await postLoginEthChallenge(body);
			await logPlausibleEvent({
				u: `/merge/ethereum/walletconnect/${slug}`,
				n: 'action'
			});
			await hydrateData();
		} catch (err) {
			console.error(err);
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
		}
	}
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
	<EthereumProgressModal
		notifications={ethereumProgressNotifs}
		on:cancel={() => {
			ethereumProgressNotifs = [];
			ethereumProgressModal = null;
		}}
		on:ok={(e) => {
			if (ethereumProgressModal === 'extension') {
				continueEthExtensionSigning(e.detail);
			} else if (ethereumProgressModal === 'walletconnect') {
				continueWalletConnectSigning(e.detail);
			}
		}}
	/>
{/if}

{#if !hideModal}
	<FullPageModal dataTest="merge-modal">
		{#if $mergeAccounts?.subject}
			{@const { slug, user_name, wallet, mastodonServer } = $mergeAccounts.subject}
			<div
				data-test="merge-account"
				class="h-auto w-full rounded-md p-3 flex flex-col items-start bg-[#DCDCDC] dark:bg-[#505050]"
			>
				<div class="flex items-center justify-start">
					<img
						src={wallet?.icon ? wallet.icon : `https://cdn.hello.coop/images/${slug}.svg`}
						class="w-9 max-h-[36px] {['apple', 'twitter', 'email', 'phone'].includes(slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
							? 'invert dark:invert-20'
							: ''}"
						alt={slug}
					/>
					<div class="flex flex-col items-start ml-3">
						{#if wallet?.name}
							{wallet.name}
						{:else}
							<span>{getDisplay(slug)}</span>
						{/if}
						<span>{mastodonServer ? `@${user_name}@${mastodonServer}` : user_name}</span>
					</div>
				</div>
			</div>
		{/if}

		<h1 class="text-center text-xl font-medium mt-4">is linked to another wallet</h1>

		{#if $mergeAccounts?.preferred?.length}
			{@const { slug, user_name, wallet, mastodonServer } = $mergeAccounts.preferred[0]}
			<div
				data-test="merge-account-2"
				class="h-auto w-full rounded-md p-3 flex flex-col items-start bg-[#DCDCDC] dark:bg-[#505050] mt-4"
			>
				<div class="flex items-center justify-start">
					<img
						src={wallet?.icon ? wallet.icon : `https://cdn.hello.coop/images/${slug}.svg`}
						class="w-9 max-h-[36px] {['apple', 'twitter', 'email', 'phone'].includes(slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
							? 'invert dark:invert-20'
							: ''}"
						alt={slug}
					/>
					<div class="flex flex-col items-start ml-3">
						<span>
							{#if wallet?.name}
								{wallet.name}
							{:else}
								{getDisplay(slug)}
							{/if}
						</span>
						<span>{mastodonServer ? `@${user_name}@${mastodonServer}` : user_name}</span>
					</div>
				</div>
			</div>
		{/if}

		{#if $mergeAccounts?.preferredSubject}
			<div class="mt-8">
				<span class="text-xl font-medium mb-2 block text-left">Merge with</span>
				{#if $mergeAccounts.preferredSubject.slug === 'email'}
					<div
						data-test="email-verify-container"
						class="relative h-auto btn-border rounded-md hover-none px-4 w-full"
					>
						<button class="h-16 w-full flex items-center justify-start">
							<MailIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'text-right mr-4'
									: 'text-left ml-4'}"
							>
								<span>
									{$_('Continue with Email')}
								</span>
								<span>{$mergeAccounts.preferredSubject.login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyEmail
								email={$mergeAccounts.preferredSubject.login_hint}
								login
								disabled
								on:success={contactLoginSuccess}
								on:error={handleContactError}
							/>
						</div>
					</div>
				{:else if $mergeAccounts.preferredSubject.slug === 'phone'}
					<div
						data-test="phone-verify-container"
						class="relative h-auto btn-border rounded-md hover-none px-4 w-full"
					>
						<button class="h-16 w-full flex items-center justify-start">
							<PhoneIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'text-right mr-4'
									: 'text-left ml-4'}"
							>
								<span>
									{$_('Continue with Phone')}
								</span>
								<span>{$mergeAccounts.preferredSubject.login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyPhone
								phone={$mergeAccounts.preferredSubject.login_hint}
								login
								disabled
								on:success={contactLoginSuccess}
								on:error={handleContactError}
							/>
						</div>
					</div>
				{:else if $mergeAccounts.preferredSubject.slug === 'ethereum'}
					<div class="relative h-auto btn-border rounded-md hover-none p-3 space-y-3 w-full">
						<span class="mb-3 block text-left"
							>{$mergeAccounts.preferredSubject.wallet?.name || 'Ethereum'}
							{#if $mergeAccounts.preferredSubject.wallet?.display}
								<span
									use:tippy={{
										content: $mergeAccounts.preferredSubject.login_hint,
										placement: 'top'
									}}
								>
									({$mergeAccounts.preferredSubject.wallet?.display})
								</span>
							{/if}
						</span>
						{#if $mergeAccounts.preferredSubject.slug === 'ethereum' && window.ethereum}
							<LoginProvider
								on:ethereum={() => continueWithEthereumExtension($mergeAccounts.preferredSubject)}
								provider={$mergeAccounts.preferredSubject}
								hideusername
								prefix="Continue with"
							/>
						{/if}
						<button
							class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
							on:click={() => continueWithWalletConnect($mergeAccounts.preferredSubject)}
						>
							<div class="flex items-center gap-x-4">
								<img
									src="https://cdn.hello.coop/images/walletconnect.svg"
									alt="WalletConnect"
									class="w-4.5 max-h-[18px]"
								/>
								<span class="block text-left">
									{$_('Continue with {provider}', { values: { provider: 'WalletConnect' } })}
								</span>
							</div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="ml-auto stroke-2 group-hover:stroke-3 h-4.5 transform text-charcoal dark:text-[#d4d4d4] opacity-80"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
							</svg>
						</button>
					</div>
				{:else}
					<LoginProvider
						login={true}
						on:click={() =>
							continueWithProvider(
								$mergeAccounts.preferredSubject.slug,
								{
									login_hint: $mergeAccounts.preferredSubject.login_hint
								},
								$mergeAccounts.preferredSubject.slug === 'mastodon'
									? $mergeAccounts.preferredSubject.mastodonServer
									: null
							)}
						provider={$mergeAccounts.preferredSubject}
					/>
				{/if}
			</div>
			<!-- <div class="mt-8">
							<button
								data-test="current-preferred-provider"
								disabled={mergeAjax}
								on:click={() => continueWithProvider($mergeAccounts.preferredSubject.slug)}
								class="btn-background w-full rounded-md p-3 h-28 flex {mergeAjax
									? 'items-center justify-center'
									: 'flex-col items-start'}"
							>
								{#if mergeAjax}
									<svg
										class="animate-spin h-7 w-7"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
									>
										<circle
											class="opacity-25"
											cx="12"
											cy="12"
											r="10"
											stroke="currentColor"
											stroke-width="4"
										/>
										<path
											class="opacity-75"
											fill="currentColor"
											d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
										/>
									</svg>
								{:else}
									<span class="text-xl font-medium mb-2">Merge with</span>
									<div class="flex items-center justify-start">
										<img
											src={$mergeAccounts.preferredSubject.wallet?.icon
												? $mergeAccounts.preferredSubject.wallet?.icon
												: `https://cdn.hello.coop/images/${$mergeAccounts.preferredSubject.slug}.svg`}
											class="w-9 max-h-[36px] {['apple', 'twitter', 'email', 'phone'].includes( //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers. 
												$mergeAccounts.preferredSubject.slug
											)
												? 'invert dark:invert-20'
												: ''}"
											alt={$mergeAccounts.preferredSubject.slug}
										/>
										<div class="flex flex-col items-start ml-3">
											{#if $mergeAccounts.preferredSubject.wallet?.name}
												<span>{$mergeAccounts.preferredSubject.wallet?.name}</span>
											{:else}
												<span>{getDisplay($mergeAccounts.preferredSubject.slug)}</span>
											{/if}

											{#if $mergeAccounts.preferredSubject.mastodonServer}
												<span
													>@{$mergeAccounts.preferredSubject.user_name}@{$mergeAccounts
														.preferredSubject.mastodonServer}</span
												>
											{:else if $mergeAccounts.preferredSubject.user_name}
												<span>{$mergeAccounts.preferredSubject.user_name}</span>
											{/if}
										</div>
									</div>
								{/if}
							</button>
						</div> -->
		{/if}

		{#if $mergeAccounts?.preferred?.length && $data.preferred?.length}
			{@const { slug, user_name, mastodonServer, wallet } = $data.preferred[0]}
			<div
				class="h-auto w-full rounded-md p-3 flex flex-col items-start bg-[#DCDCDC] dark:bg-[#505050] mt-8"
			>
				<span class="text-xl font-medium mb-2">Merge with</span>
				<div data-test="current-preferred-provider" class="flex items-center justify-start">
					<img
						src={wallet?.icon ? wallet.icon : `https://cdn.hello.coop/images/${slug}.svg`}
						class="w-9 max-h-[36px] {['apple', 'twitter', 'email', 'phone'].includes(slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
							? 'invert dark:invert-20'
							: ''}"
						alt={slug}
					/>
					<div class="flex flex-col items-start ml-3">
						{#if wallet?.name}
							{wallet.name}
						{:else}
							<span>{getDisplay(slug)}</span>
						{/if}
						<span>{mastodonServer ? `@${user_name}@${mastodonServer}` : user_name}</span>
					</div>
				</div>
				<span class="text-xl font-medium my-4 mx-auto text-center">by verifying</span>
				{#if $mergeAccounts.preferred[0].slug === 'email'}
					<div
						data-test="email-verify-container"
						class="relative h-auto btn-border rounded-md hover-none px-4 w-full"
					>
						<button class="h-16 w-full flex items-center justify-start">
							<MailIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'text-right mr-4'
									: 'text-left ml-4'}"
							>
								<span>
									{$_('Continue with Email')}
								</span>
								<span>{$mergeAccounts.preferred[0].login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyEmail
								email={$mergeAccounts.preferred[0].login_hint}
								login
								disabled
								on:success={contactLoginSuccess}
								on:error={handleContactError}
							/>
						</div>
					</div>
				{:else if $mergeAccounts.preferred[0].slug === 'phone'}
					<div
						data-test="phone-verify-container"
						class="relative h-auto btn-border rounded-md hover-none px-4 w-full"
					>
						<button class="h-16 w-full flex items-center justify-start">
							<PhoneIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'text-right mr-4'
									: 'text-left ml-4'}"
							>
								<span>
									{$_('Continue with Phone')}
								</span>
								<span>{$mergeAccounts.preferred[0].login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyPhone
								phone={$mergeAccounts.preferred[0].login_hint}
								login
								disabled
								on:success={contactLoginSuccess}
								on:error={handleContactError}
							/>
						</div>
					</div>
				{:else if $mergeAccounts.preferred[0].slug === 'ethereum'}
					<div class="relative h-auto btn-border rounded-md hover-none p-3 space-y-3 w-full">
						<span class="mb-3 block text-left"
							>{$mergeAccounts.preferred[0].wallet?.name || 'Ethereum'}
							{#if $mergeAccounts.preferred[0].wallet?.display}
								<span
									use:tippy={{
										content: $mergeAccounts.preferred[0].login_hint,
										placement: 'top'
									}}
								>
									({$mergeAccounts.preferred[0].wallet?.display})
								</span>
							{/if}
						</span>
						{#if $mergeAccounts.preferred[0].slug === 'ethereum' && window.ethereum}
							<LoginProvider
								on:ethereum={() => continueWithEthereumExtension($mergeAccounts.preferred[0])}
								provider={$mergeAccounts.preferred[0]}
								hideusername
								prefix="Continue with"
							/>
						{/if}
						<button
							class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
							on:click={() => continueWithWalletConnect($mergeAccounts.preferred[0])}
						>
							<div class="flex items-center gap-x-4">
								<img
									src="https://cdn.hello.coop/images/walletconnect.svg"
									alt="WalletConnect"
									class="w-4.5 max-h-[18px]"
								/>
								<span class="block text-left">
									{$_('Continue with {provider}', { values: { provider: 'WalletConnect' } })}
								</span>
							</div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="ml-auto stroke-2 group-hover:stroke-3 h-4.5 transform text-charcoal dark:text-[#d4d4d4] opacity-80"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
							</svg>
						</button>
					</div>
				{:else}
					<LoginProvider
						login={true}
						on:click={() =>
							continueWithProvider(
								$mergeAccounts.preferred[0].slug,
								{
									login_hint: $mergeAccounts.preferred[0].login_hint
								},
								$mergeAccounts.preferred[0].slug === 'mastodon'
									? $mergeAccounts.preferred[0].mastodonServer
									: null
							)}
						provider={$mergeAccounts.preferred[0]}
						prefix="Continue with"
					/>
				{/if}
			</div>
		{/if}

		{#if !$mergeAccounts?.preferred & !$mergeAccounts?.preferredSubject && $data.preferred?.length}
			<div class="mt-6 w-full">
				<button
					data-test="current-preferred-provider"
					disabled={mergeAjax}
					on:click={confirmMerge}
					class="btn-background w-full rounded-md p-3 h-28 flex {mergeAjax
						? 'items-center justify-center'
						: 'flex-col items-start'}"
				>
					{#if mergeAjax}
						<SpinnerIcon css="h-7 w-7" />
					{:else}
						<span class="text-xl font-medium mb-2">{$_('Merge with')}</span>
						<div class="flex items-center justify-start">
							<img
								src={$data?.preferred?.[0].wallet?.icon ||
									`https://cdn.hello.coop/images/${$data?.preferred?.[0].slug}.svg`}
								class="w-9 max-h-[36px] {['apple', 'twitter', 'email', 'phone'].includes(
									//These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
									$data?.preferred?.[0].slug
								)
									? 'invert dark:invert-20'
									: ''}"
								alt={$data?.preferred?.[0].slug}
							/>
							<div class="flex flex-col items-start ml-3">
								{#if $data?.preferred?.[0].wallet?.name}
									<span>{$data?.preferred?.[0].wallet?.name}</span>
								{:else}
									<span>{getDisplay($data?.preferred?.[0].slug)}</span>
								{/if}

								{#if $data?.preferred?.[0].mastodonServer}
									<span
										>@{$data?.preferred?.[0].user_name}@{$data?.preferred?.[0].mastodonServer}</span
									>
								{:else if $data?.preferred?.[0].user_name}
									<span>{$data?.preferred?.[0].user_name}</span>
								{/if}
							</div>
						</div>
					{/if}
				</button>
			</div>
		{/if}

		<div class="flex justify-start">
			<button
				on:click={cancelMerge}
				data-test="cancel-btn"
				type="button"
				class="mt-6 inline-block text-left focus:underline hover:underline">{$_('Cancel')}</button
			>
		</div>
	</FullPageModal>
{/if}
